import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Grid from "../../../../../components/grid"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Poem from "../../../../../components/poem"
import OrdnungTask from "../../../../../content/kurse/versteckte-ordnung/02-ordnung/task"
import PaperStack from "../../../../../components/paper-stack"

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "versteckte-ordnung",
          chapterId: "02-ordnung",
          taskId: "feuerreiter",
        })
        navigate("/kurse/versteckte-ordnung/02-ordnung/feuerreiter/loesung")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Eduard Mörike: Der Feuerreiter" />
      <Stack>
        <Stack space={3}>
          <Heading as="span">Eduard Mörike</Heading>
          <Heading as="h1" level={2}>
            Der Feuerreiter
          </Heading>
        </Stack>
        <Grid columns={[1, 2]}>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <span>Sehet ihr am Fensterlein</span>
              <span>Dort die rothe Mütze wieder?</span>
              <span>Nicht geheuer muß es sein,</span>
              <span>Denn er geht schon auf und nieder.</span>
              <span>Und auf einmal welch Gewühle</span>
              <span>Bei der Brücke, nach dem Feld!</span>
              <span>Horch! Das Feuerglöcklein gellt:</span>
              <span>Hinter’m Berg,</span>
              <span>Hinter’m Berg</span>
              <span>Brennt es in der Mühle!</span>
              <br />
              <span>Schaut! da sprengt er wüthend schier</span>
              <span>Durch das Thor, der Feuerreiter,</span>
              <span>Auf dem rippendürren Thier,</span>
              <span>Als auf einer Feuerleiter!</span>
              <span>Querfeldein! Durch Qualm und Schwüle</span>
              <span>Rennt er schon, und ist am Ort!</span>
              <span>Drüben schallt es fort und fort:</span>
              <span>Hinter’m Berg,</span>
              <span>Hinter’m Berg</span>
              <span>Brennt es in der Mühle!</span>
              <br />
              <span>...</span>
            </Poem>
          </PaperStack>
          <OrdnungTask />
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
